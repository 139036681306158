<template lang="pug">
div
  .testimonial_social_191.content_asset(:class="shape" v-for="(p, index) in the_pages" :key="index")
    .header
      figure(v-html='account.svg_logo')
      a(:href='asset_url' target='_blank' :style='horizontal_gradient')
        Logo
        | {{asset_link}}
    .testimonial
      h2
        span(v-if="index === 0") "
        span(v-if="index > 0")
          | &hellip;
        span(v-html='p')
        span(v-if="showEllipsis(index)")
          | &hellip;
        span(v-if="index === total_page_count - 1") "
    .footer
      .avatar(:style='horizontal_gradient')
        AvatarIcon
      .author_information
        h4(v-if='renderableTestimonial.attribution_line_1') {{renderableTestimonial.attribution_line_1}}
        h6 {{renderableTestimonial.attribution_line_2}}
        h6 {{renderableTestimonial.attribution_line_3}}
    .gradient(:style='vertical_gradient' v-if='lastPage(index)')
    .arc1
      div(v-if='total_page_count > 1') {{pageIndicator(index)}}
    .arc2
      div(v-if='index+1 != total_page_count')
        RightArrowIcon
</template>
<script>
import Logo from './graphics/Logo.vue'
import AvatarIcon from './graphics/AvatarIcon.vue'
import paginate from '../lib/paginate'
import RightArrowIcon from './graphics/RightArrowIcon.vue'
import { gradientColors, headerColor } from '@lib/colors'
import { generateShareLink } from '@lib/share_link'

export default {
  name: 'TestimonialSocial191',
  components: { Logo, AvatarIcon, RightArrowIcon },
  props: {
    renderableTestimonial: {
      type: Object,
      required: true,
    },
    shape: {
      type: String,
      default: 'flex',
    },
    page: {
      type: Number,
      default: 1,
    },
    show_all_pages: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Object,
      required: true,
    },
  },
  computed: {
    the_shape() {
      return this.shape || 'flex' // default to flex
    },
    the_page_num() {
      return this.page || 1 // default to first page
    },
    // This is the _visible_ content, even for a single page. When showing a single
    // page this will be an array with the current page (only). When show_all_pages == true
    // this will have all pages.
    the_pages() {
      const all_pages = this.all_the_pages
      return this.show_all_pages ? all_pages : [all_pages[this.the_page_num - 1]]
    },
    all_the_pages() {
      // skip pagination when shape is flex
      if (this.the_shape === 'flex') {
        return [this.renderableTestimonial.testimonial_text]
      }
      const wide_max_chars = 225
      const square_max_chars = 780
      const max_page_chars = this.shape === 'wide' ? wide_max_chars : square_max_chars
      const text = this.renderableTestimonial.testimonial_text
      return paginate(text, max_page_chars)
    },
    total_page_count() {
      return this.all_the_pages.length
    },
    asset_link() {
      return `uevi.co/${this.renderableTestimonial.identifier}`
    },
    asset_url() {
      return generateShareLink(this.renderableTestimonial.identifier)
    },
    horizontal_gradient() {
      const [startColor, endColor] = this.gradientColors
      return {
        background: `linear-gradient(90deg, ${startColor}, ${endColor})`,
      }
    },
    vertical_gradient() {
      return {
        background: `linear-gradient(180deg, ${this.headerColor} 0%, hsla(200, 100%, 100%, 0) 100%)`,
        transform: 'matrix(1, 0, 0, -1, 0, 0)',
      }
    },
    person_attribution() {
      // ready testimonial
      return this.renderableTestimonial.author_name || this.renderableTestimonial.author_title
    },
    gradientColors,
    headerColor,
  },
  methods: {
    pageIndicator(page) {
      const visible_page_number = this.page ? this.the_page_num : page + 1
      return `${visible_page_number} / ${this.total_page_count}`
    },
    lastPage(i) {
      return i == this.total_page_count - 1
    },
    showEllipsis(index) {
      const index_or_page_prop = this.page ? this.page : index + 1
      return this.total_page_count > 1 && index_or_page_prop < this.total_page_count
    },
  },
}
</script>
<style lang="sass" scoped>
.content_asset
  border-radius: 0px !important
  border: none !important

.testimonial_social_191
  background: white
  width: 616px
  padding: 32px 48px
  position: relative
  overflow: hidden

  &.square, &.wide
    display: flex
    flex-direction: column
    justify-content: center

  // The dimensions in these next two shape classess must match
  // the SHAPE_MAP constant in RenderableTestimonial.rb model
  &.square
    // aspec-ratio is not supported by older puppeteer
    // aspect-ratio: 1 / 1
    width: 624px
    height: 624px
  &.wide
    // aspect-ratio: 16 / 9 (+1 px)
    width: 624px
    height: 352px

  .gradient
    z-index: 10
    position: absolute
    width: 32px
    height: 100%
    top: 0
    right: 0
  .arc1, .arc2
    position: absolute
    z-index: 9
    width: 196px
    height: 196px
    border: 4px solid hsl(200, 24%, 96%)
    border-radius: 50%
    bottom: -104px
    div
      position: absolute
  .arc1
    left: -104px
    div
      top: 46px
      right: 40px
      font-size: 12px

  .arc2
    right: -104px
    div
      top: 46px
      left: 40px
      font-size: 12px
  .header
    z-index: 11
    display: flex
    margin-bottom: 27px
    figure
      height: 24px
      margin: 0
      padding: 0
    a
      position: absolute
      right: 0
      font-weight: 800
      font-size: 9px
      border-radius: 20px 0 0 20px
      line-height: 1
      padding: 6px 48px 6px 6px
      color: white
      display: flex
      align-items: center
      svg::v-deep
        width: 12px
        height: 12px
        margin-right: 12px
        path
          fill: hsla(0, 0%, 100%, 0.6) !important
  .testimonial
    margin-bottom: 27px
    h2::v-deep
      font-size: 18px
      line-height: 26px
      font-weight: 400
      letter-spacing: -0.015em
      strong
        font-weight: 800 !important
      span
        div
          display: inline
  .footer
    position: relative
    z-index: 11
    display: flex
    height: 48px
    h4, h6
      color: hsl(200, 8%, 8%)
      font-weight: 500
      letter-spacing: -0.02em
    .avatar
      margin-right: 12px
      height: 48px
      width: 48px
      border-radius: 32px 32px 32px 0px
      display: flex
      align-items: center
      position: relative
      img
        border-radius: 40px
        width: 48px
      svg
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        ::v-deep path
          fill: hsla(200, 100%, 100%, 0.9) !important
    h4
      font-size: 14px
      line-height: 16px
      margin-bottom: 4px
    h6
      font-size: 10px
      line-height: 12px
      letter-spacing: inherit

    .author_information
      display: flex
      flex-direction: column
      text-align: left
      justify-content: center
      h6:not(:last-child)
        margin-bottom: 4px
</style>
