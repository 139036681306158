<template>
  <div
    class="branded_style_asset branded_style_matrixx px-4"
    :class="{ 'brand-font-enabled': brandFont }"
    :style="bothBackgroundCssVars"
  >
    <div class="container pt-3 pb-3 brand-font">
      <BrandedHeader
        :logoSvg="account.svg_logo_mark"
        :text="matrixxChart.question_text"
        :logoSizeOption="logoSizeOption"
      />
      <MatrixxGutsBasic :matrixxChart="matrixxChart" :account="account" :colorChoice="statColor" />
    </div>

    <BrandedFooter
      :verifiedAt="matrixxChart.verified_at"
      :ueId="matrixxChart.identifier"
      :color="footerColor"
      :surveySummary="brandedSourceText"
      class="mt-3"
    />
  </div>
</template>
<script lang="ts">
import AssetSourceMixin from '@app/mixins/asset_source'
import MatrixxGutsBasic from './basic_asset_parts/matrixx_chart/MatrixxGutsBasic.vue'
import BrandedFooter from '@components/branded_asset_parts/BrandedFooter.vue'
import BrandedHeader from '@components/branded_asset_parts/BrandedHeader.vue'
import BrandedAssetMixin from '@app/mixins/branded_asset.ts'
import { injectFontFamily } from '@lib/injectFont'

export default {
  name: 'MatrixxChartBranded',
  components: { BrandedFooter, BrandedHeader, MatrixxGutsBasic },
  mixins: [AssetSourceMixin, BrandedAssetMixin],
  props: {
    matrixxChart: { type: Object, required: true },
    account: { type: Object, required: true },

    // Matrixx segments color
    statColorOption: { type: String, default: 'primary' },
  },
  beforeMount() {
    if (this.brandFont) {
      injectFontFamily(this.brandFont)
    }
  },
  computed: {
    // this is a shim for the mixin... which is looking for contentAsset instead
    // of matrixxChart
    contentAsset() {
      return this.matrixxChart
    },
    brandFont() {
      return this.account?.brand_font
    },
  },
}
</script>
<style lang="sass" scoped>
.branded_style_matrixx .container
  border-top-left-radius: 0
  border-top-right-radius: 0
</style>
