<template lang="pug">
//- @TODO new namespace!
.spotlight_asset.customer-spotlight(v-if='customer_spotlight' :class='{ horizontal: horizontal }' :style="`--accent-color: ${customer_spotlight.accent_color}`")
  ReportHeader(:account='account', :contentAsset='customer_spotlight', :backUrl='backUrl', :backText='backText')
  .content
    .content_container
      section.block.customer_profile(v-if='customer_spotlight.show_customer_profile')
        h1.text-block__title Customer Profile
        .facts
          .fact(v-if='size_group == "fortune_500"')
            Fortune500Icon(:brand_color_1='account.brand_color_1' :brand_color_2='this.customer_spotlight.accent_color')
            | Fortune 500 Company
          .fact(v-if='size_group')
            CompanySizeIcon(:brand_color_1='account.brand_color_1' :brand_color_2='this.customer_spotlight.accent_color')
            | {{size_group}}
          .fact(v-if='customer_spotlight.recipient.best_industry_name')
            IndustryIcon(:brand_color_1='account.brand_color_1' :brand_color_2='this.customer_spotlight.accent_color')
            | {{customer_spotlight.recipient.best_industry_name}}
          .fact(v-if='customer_spotlight.recipient.company_country_code')
            LocationIcon(:brand_color_1='account.brand_color_1' :brand_color_2='this.customer_spotlight.accent_color')
            | {{customer_spotlight.recipient.company_country_code}}

      template(v-for="block in customer_spotlight.blocks")
        TextBlock(v-if="block.type === 'Text' && block.show", :title="block.title" :text="block.text" :generate_auto_summary="block.generate_auto_summary")
        QuestionBlock(v-if="block.type === 'Question' && block.show", :prompt="block.prompt" :answers="block.answers")
        TestimonialBlock(
          v-if="block.type === 'Testimonial' && block.show",
          :quote="block.quote",
          :author_name="block.author_name",
          :author_title="block.author_title",
          :author_company="block.author_company"
        )
        KeyStatBlock(
          v-if="block.type === 'Key Stat' && block.show"
          :number="block.number",
          :qualifier="block.qualifier",
          :tagline="block.tagline"
        )

    .spotlight_footer
      .ue_logo
        UELogo
      .disclaimer(v-if='customer_spotlight.recipient.named') Source: {{customer_spotlight.recipient.person_attribution}}, {{customer_spotlight.recipient.company_attribution}}.  Independent research conducted by UserEvidence. Data verified {{published_at | dayjs('MMMM DD, YYYY')}}.
      .disclaimer(v-else) Source: Survey of {{ unnamed_company_reference }}. Independent research conducted by UserEvidence. Data verified {{published_at | dayjs('MMMM DD, YYYY')}}.
      .ueid-container
        .ueid
          | UEID: {{customer_spotlight.identifier}}
          span
        .url
          AssetLink(:assetIdentifier='customer_spotlight.identifier' :account='account')
</template>
<script lang="ts">
import ReportHeader from './basic_asset_parts/reports/ReportHeader.vue'
import TestimonialHighlight from './TestimonialHighlight.vue'
import BackArrow from './graphics/BackArrow.vue'
import UELogo from './graphics/UELogo.vue'
import AvatarIcon from './graphics/AvatarIcon.vue'
import Fortune500Icon from './graphics/Fortune500Icon.vue'
import CompanySizeIcon from './graphics/CompanySizeIcon.vue'
import IndustryIcon from './graphics/IndustryIcon.vue'
import LocationIcon from './graphics/LocationIcon.vue'
import DownloadIcon from './graphics/DownloadIcon.vue'
import { companyReference } from '../lib/customerSpotlightUtils'
import TextBlock from './blocks/TextBlock.vue'
import QuestionBlock from './blocks/QuestionBlock.vue'
import TestimonialBlock from './blocks/TestimonialBlock.vue'
import KeyStatBlock from './blocks/KeyStatBlock.vue'
import AssetLink from '@components/AssetLink.vue'

export default {
  components: {
    ReportHeader,
    UELogo,
    AvatarIcon,
    Fortune500Icon,
    CompanySizeIcon,
    IndustryIcon,
    LocationIcon,
    TestimonialHighlight,
    BackArrow,
    DownloadIcon,
    TextBlock,
    QuestionBlock,
    TestimonialBlock,
    KeyStatBlock,
    AssetLink,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    customer_spotlight: {
      type: Object,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    size_group() {
      const size =
        this.customer_spotlight.recipient.best_size_group ||
        this.customer_spotlight.recipient.size_group
      if (size) {
        const sizes = {
          small_business: '1-50',
          medium_enterprise: '50-1000',
          large_enterprise: '> 1000',
          fortune_500: '> 5000',
        }
        return sizes[size]
      } else return null
    },
    unnamed_company_reference() {
      return companyReference(this.customer_spotlight.recipient)
    },
    published_at() {
      return this.customer_spotlight.verified_at || new Date()
    },
    backUrl() {
      return this.$cookies.get('site-back-url') && this.$cookies.get('site-back-url').length > 5
        ? this.$cookies.get('site-back-url')
        : `/user-research-library/${this.account.slug}`
    },
    backText() {
      return this.$cookies.get('site-back-text') && this.$cookies.get('site-back-text').length > 5
        ? this.$cookies.get('site-back-text')
        : `${this.account.name} Home`
    },
  },
  methods: {
    testimonial(testimonial) {
      return {
        text_answer: testimonial.answers[0].response.text_answer,
        recipient: this.customer_spotlight.recipient,
        account: this.account,
      }
    },
    statMidpoint(stat) {
      return Math.round((stat.answers[0].answer.low_value + stat.answers[0].answer.high_value) / 2)
    },
    pluralize(stat) {
      return this.statMidpoint(stat) == 1 ? stat.qualifier.slice(0, -1) : stat.qualifier
    },
    formatPrice(amount) {
      return amount.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    },
  },
}
</script>
<style lang="sass">
// .spotlight_asset is shared with survey and account reports
// .customer-spotlight is unique to this component
.spotlight_asset.customer-spotlight
  // This creates consistency between the public/site context and the app because
  // in the app pack the base font size is 16 and in the site pack it's 20px. This
  // creates potential inconsistencies when sizing things in EMs
  font-size: 16px

  .content
    section.block
      margin: 0
      margin-top: 3em

  section.block.key-stat-block + section.block.key-stat-block
    margin-top: 0em

  section.block.text-block--header + section.block
    margin-top: 0em

  .spotlight_footer
    margin-top: 3rem
    max-width: 816px // This matches .content max-width

  .customer_profile
    .fact
      svg path
        // The icons are actually designed to have 2 colors. These used to be the account brand
        // colors 1 and 2. Now the icon colors are dynmaically set from the accent color, and so
        // for now the icons are monochromatic. To restore the two-tone effect, remove the following
        // style and instead pass in a second variation of the accent color as a prop to the icons
        // in the customer profile section.
        stroke: var(--accent-color)
</style>
