<template>
  <div class="bar_guts_basic" :class="orientation">
    <div class="bar_group" v-for="stat in nonZeroStats" :key="stat.id">
      <div class="channel">
        <div class="bar" :style="barStyle(stat.count)">
          <div class="stat">{{ statPercent(stat.count) }}<span>%</span></div>
        </div>
      </div>
      <div class="answer">{{ stat.freeform ? 'Other' : stat.the_answer }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // This is needed by functions imported from colors.ts
    account: { type: Object, required: true },
    stats: {
      type: Array,
    },
    total: { type: Number, default: 0 },
    chartColor: { type: String },
  },
  computed: {
    nonZeroStats() {
      return this.stats.filter((s) => s.count > 0 && !s.exclude_from_asset)
    },
    shortChart() {
      return Math.max(...this.nonZeroStats.map((stat) => Number(this.statPercent(stat.count)))) < 50
    },
    orientation() {
      return Math.max(...this.nonZeroStats.map((s) => s.the_answer && s.the_answer.length), 0) > 10
        ? 'horizontal'
        : 'vertical'
    },
    maxPercent() {
      return Math.max(...this.nonZeroStats.map((stat) => Number(this.statPercent(stat.count))))
    },
  },
  methods: {
    statPercent(value) {
      return Math.round((value / this.total) * 100)
    },
    barStyle(value) {
      if (this.orientation == 'horizontal') {
        var width = this.statPercent(value)
        if (this.shortChart) width = width * 1.9
        return {
          width: width + '%',
          background: `${this.chartColor}`,
        }
      } else {
        var height = Number(this.statPercent(value))
        return {
          height: (height / this.maxPercent) * 100 + '%',
          background: `${this.chartColor}`,
        }
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.bar_guts_basic
  height: 100%
  width: 100%
  margin: 0 auto
  padding-top: 1em

.horizontal
  .bar_group
    &:not(:last-child)
      margin-bottom: 16px
  .bar
    border-radius: 0 8px 8px 0
    padding: 0
    height: 37px
    color: white
    display: flex
    align-items: flex-end
    min-width: 48px
  .answer
    margin-top: 4px
    width: 100%
  .stat
    bottom: 1px
    left: 12px

.vertical
  height: 350px
  display: flex
  justify-content: space-between
  align-items: flex-end
  .channel
    height: 100%
    display: flex
    flex-direction: column
    justify-content: flex-end
  .bar_group
    display: flex
    flex-direction: column
    justify-content: flex-end
    width: 100%
    height: 100%
    &:not(:last-child)
      margin-right: 2%
  .stat
    bottom: 1px
  .bar
    border-radius: 8px 8px 0px 0px
    padding: 0
    color: white
    display: flex
    justify-content: center
    align-items: flex-end
    height: 100%
    max-width: 100%
  .answer
    margin-top: 8px
    justify-content: center

.vertical .answer, .horizontal .answer
  display: flex
  letter-spacing: -0.02em
  font-size: 12px
  font-weight: 400
  line-height: 15px

.stat
  font-size: 30px
  letter-spacing: -0.04em
  line-height: 1
  position: relative
  bottom: -4px
  text-shadow: 1px 0px 0 hsla(200, 8%, 8%, 0.5)
  span
    font-size: 14px
    letter-spacing: 0
    line-height: 1
    color: hsla(200, 100%, 100%, 0.8)
    position: relative
    left: 2px


.bar_guts_basic, .bar_group, .stat
  font-weight: 800
</style>
