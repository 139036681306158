<template>
  <section class="block text-block" :class="{ 'text-block--header': !text }">
    <h1 class="text-block__title" v-if="title">{{ title }}</h1>
    <div class="d-flex align-items-center mt-3">
      <div
        v-if="generate_auto_summary"
        class="spinner-border spinner-border-sm text-primary mr-2"
        role="status"
      >
        <span class="sr-only">Generating...</span>
      </div>
      <p v-if="text">{{ text }}</p>
    </div>
  </section>
</template>
<script lang="ts">
export default {
  props: ['title', 'text', 'generate_auto_summary'],
}
</script>
