import randomHash from './random-hash'
import Color from 'color'
import dayjs from 'dayjs'

const defaultIntro = (recipient, account) => {
  const survey_date = recipient.completed_at
    ? `${dayjs(recipient.completed_at).format('MMMM')} ${dayjs(recipient.completed_at).format('YYYY')}`
    : ''
  return `This Customer Spotlight is a synopsis of how ${companyReference(recipient)} used ${account.name} to benefit their business. The feedback included was collected and verified in a ${survey_date} survey of ${account.name} customers conducted by UserEvidence, an independent research firm.`
}

const companyReference = (recipient) => {
  // if (!this.recipient_data) { return null }
  // const recipient = this.recipient_data.survey.recipients[0]
  if (recipient.named && recipient.best_company_name) return recipient.best_company_name
  else {
    const raw_size_group = recipient.best_size_group || recipient.size_group
    const size_string = raw_size_group
      ? raw_size_group
          .split('_')
          .map((w) => w[0].toUpperCase() + w.substring(1))
          .join(' ')
      : ''
    return `a ${size_string} ${recipient.best_industry_name} company`
  }
}

const makeKeyStatBlock = (response, question, hash) => {
  // value_answer is an integer for NPS
  let number = response.value_answer
  // for other multiple choice questions we have to calculate a number...
  if (!number) {
    const answer = question.answers.find((a) => a.id === response.answer_id)

    if (answer.low_value && answer.high_value) {
      // here we invent a single integer by averaging the pre-set low and high value
      // attributes of the question's potential answers. This feels stinky and weird
      // (to Emerson) but management insists this is a essential functionality 🤷‍♂️
      number = (answer.low_value + answer.high_value) / 2
      number = Math.round(number)
    } else {
      // abort the mission if we don't have the high/low values
      // This is expected for answers like "I don't know" or "Other"
      return
    }
  }

  return {
    type: 'Key Stat',
    number: number,
    qualifier: question.qualifier,
    tagline: question.stat_tagline,
    show: true,
    key: hash,
  }
}

const makeCustomerSpotlightFromData = (recipient, questions, account, autoSummaries) => {
  // Build Stat blocks
  const keyStatBlocks = questions.reduce((result, question) => {
    const response = recipient.responses.find((r) => r.question_id === question.id)

    if (response && question.stat_tagline && question.qualifier) {
      const statBlock = makeKeyStatBlock(response, question, randomHash())
      if (statBlock) {
        result.push(statBlock)
      }
    }
    return result
  }, [])

  // Build Testimonial blocks
  const testimonialBlocks = questions.reduce((result, question) => {
    const response = recipient.responses.find((r) => r.question_id === question.id && r.text_answer)

    if (response && question.type === 'Testimonial') {
      result.push({
        type: 'Testimonial',
        quote: response.text_answer,
        author_name: recipient.named ? `${recipient.first_name} ${recipient.last_name}` : null,
        author_title: recipient.title,
        author_company: recipient.company_attribution,
        show: true,
        key: randomHash(),
        original_question: question,
      })
    }
    return result
  }, [])

  const scenarioQuestionBlocks = []
  const outcomeQuestionBlocks = []
  const miscQuestionBlocks = []
  questions.map((q) => {
    const answers = recipient.responses
      .filter((r) => r.question_id === q.id)
      .map((r) => {
        return (
          r.text_answer ||
          r.value_answer?.toString() ||
          q.answers.find((a) => r.answer_id === a.id).the_answer
        )
      })

    // Skip if question has no answer
    if (!answers?.length) return

    const block = {
      type: 'Question',
      prompt: q.the_question,
      answers: answers,
      show: false, // we update this conditionall below
      key: randomHash(),
      original_question: q,
    }

    switch (q.category) {
      case 'scenario':
        block.show = true
        scenarioQuestionBlocks.push(block)
        return
      case 'outcome':
        // NPS is an outcome but in most cases it's already a key stat, so hide
        block.show = q.type === 'Nps' ? false : true
        outcomeQuestionBlocks.push(block)
        return
      default:
        miscQuestionBlocks.push(block)
    }
  })

  const hasQuestionsWithShowTrue = [...scenarioQuestionBlocks, ...outcomeQuestionBlocks].some(
    (b) => b.show,
  )

  const generateAutoSummary = autoSummaries && hasQuestionsWithShowTrue

  const blocks = [
    {
      type: 'Text',
      title: 'Introduction',
      text: generateAutoSummary ? 'Generating introduction...' : defaultIntro(recipient, account),
      show: true,
      generate_auto_summary: generateAutoSummary ? 'introduction' : false,
      key: randomHash(),
    },
  ]

  if (keyStatBlocks.length) {
    const plural = keyStatBlocks.length > 1 ? 's' : ''
    blocks.push(
      {
        type: 'Text',
        title: `Key Stat${plural}`,
        text: null,
        show: true,
        key: randomHash(),
      },
      ...keyStatBlocks,
    )
  }

  if (testimonialBlocks.length) {
    blocks.push(...testimonialBlocks)
  }

  if (scenarioQuestionBlocks.filter((b) => b.show).length) {
    blocks.push(
      {
        type: 'Text',
        title: 'Scenario',
        text: generateAutoSummary ? 'Generating scenario...' : null,
        generate_auto_summary: generateAutoSummary ? 'scenario' : false,
        show: true,
        key: randomHash(),
      },
      ...scenarioQuestionBlocks,
    )
  }

  if (outcomeQuestionBlocks.filter((b) => b.show).length) {
    blocks.push(
      {
        type: 'Text',
        title: 'Outcome',
        text: generateAutoSummary ? 'Generating outcome...' : null,
        generate_auto_summary: generateAutoSummary ? 'outcome' : false,
        show: true,
        key: randomHash(),
      },
      ...outcomeQuestionBlocks,
    )
  }

  if (account?.introduction) {
    blocks.push({
      type: 'Text',
      title: `About ${account.name}`,
      text: account.introduction,
      show: true,
      key: randomHash(),
    })
  }

  if (miscQuestionBlocks.length) {
    blocks.push(...miscQuestionBlocks)
  }

  // Native color picker only supports HEX value
  const defaultAccentColor = Color(account.brand_color_1 || account.brand_color_2).hex()

  return {
    title: null,
    survey_id: recipient.survey_id,
    recipient_id: recipient.id,
    identifier: 'TBD',
    recipient: recipient,
    show_customer_profile: true,
    accent_color: defaultAccentColor,
    blocks: blocks,
    classifier_values: [],
  }
}

const colorToHex = (color) => Color(color).hex()

export {
  makeCustomerSpotlightFromData,
  defaultIntro,
  companyReference,
  makeKeyStatBlock,
  colorToHex,
}
