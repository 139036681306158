<template lang="pug">
.spotlight_asset(v-if='content_asset' :class='{ horizontal: horizontal }')
  ReportHeader(:account='content_asset.account' :contentAsset='content_asset' :backUrl='backUrl' :backText='backText')
  .content
    .content_container
      section(v-if='content_asset.filter == "company"')
        h5 Customer Profile
        .facts
          .fact(v-if='content_asset.company.is_fortune_500')
            Fortune500Icon(:brand_color_1='primary_color' :brand_color_2='secondary_color')
            | Fortune 500
          .fact(v-if='content_asset.company.size_group')
            CompanySizeIcon(:brand_color_1='primary_color' :brand_color_2='secondary_color')
            | {{size_groups[content_asset.company.size_group]}}
          .fact
            IndustryIcon(:brand_color_1='primary_color' :brand_color_2='secondary_color')
            | {{content_asset.company.sector_name}}
          .fact
            LocationIcon(:brand_color_1='primary_color' :brand_color_2='secondary_color')
            span(v-if='content_asset.company.country_code') {{ content_asset.company.country_code }}
            span(v-else) US
      section(v-else)
        h5 Respondents Profile
        .facts
          .fact
            CompanySizeIcon(:brand_color_1='primary_color' :brand_color_2='secondary_color')
            | {{content_asset.respondent_count}} Respondents
          .fact
            IndustryIcon(:brand_color_1='primary_color' :brand_color_2='secondary_color')
            | {{sector_qualifier}}
          .fact
            Fortune500Icon(:brand_color_1='primary_color' :brand_color_2='secondary_color')
            | {{content_asset.company_count}} {{company_qualifier}}
          .fact
            LocationIcon(:brand_color_1='primary_color' :brand_color_2='secondary_color')
            span(v-if='this.content_asset.survey.country_count > 1') {{ country_count}}
            span(v-else) US

      section
        h5 Introduction
        p.intro_text(v-if='content_asset.filter == "company"')
          | This Account Spotlight is a synopsis of how {{ company_article }} {{content_asset.company.name}}, used {{content_asset.account.name}} to benefit their business. The feedback included was collected from {{content_asset.respondent_count}} users of the account in a {{ content_asset.survey.first_sent_at | dayjs('MMMM YYYY') }} survey of {{ content_asset.named ? '' : 'the' }} {{content_asset.company.name}} customers.
        p.intro_text(v-else)
          | This Survey Spotlight is a synopsis of how {{content_asset.account.name}} is received by its customers.  The feedback included was collected and verified in a survey, conducted between {{ content_asset.survey.first_sent_at | dayjs('MMMM Do, YYYY') }} - {{ content_asset.survey.last_response_at | dayjs('MMMM Do, YYYY')}}, of {{content_asset.survey.respondent_count}} {{content_asset.account.name}} customers conducted by UserEvidence, an independent research firm.
      section(v-if='this.multiple_choice_stat_questions.length > 0')
        h5 Key Results
        .stats(:class='stats_class')
          .stat(v-for='stat in this.multiple_choice_stat_questions')
            h2(v-if='stat.aggregate_qualifier == "$"')
              .qualifier(:style='text_color_1') {{stat.aggregate_qualifier}}
              | {{formatPrice(stat.aggregate_stat_value)}}
            h2(v-else)
              | {{stat.aggregate_stat_value}}
              .qualifier(:style='text_color_1') {{pluralize(stat)}}
            h6 {{stat.aggregate_stat_tagline}}
          .stat(v-if='nps')
            h2
              | {{nps.aggregate_stat_value}}
              .qualifier(:style='text_color_1') {{nps.aggregate_qualifier}}
            h6 {{nps.aggregate_stat_tagline}}
      section(v-if='key_testimonials')
        .testimonial(v-if='key_testimonials.length > 1')
          h5 Key Testimonials
          TestimonialHighlight(:testimonials='key_testimonials' :hide_company_attribution='content_asset.filter == "company"')

      section
        .chart_preview
          h4 {{scenario_question.the_question}}
          .bar_chart
            BarGuts(:stats='scenario_question.stats' :total='scenario_question.effective_response_count' :account='account')
          .citation Source: Survey of {{scenario_question.response_count}} {{account.name}} customers.

      section(v-for='(question, i) in content_asset.questions')
        .testimonial(v-if='testimonial_groups[i] && testimonial_groups[i].length > 0')
          h5 Testimonials
          TestimonialHighlight(:testimonials='testimonial_groups[i]' :hide_company_attribution='content_asset.filter == "company"')
        .chart_preview
          h4 {{question.the_question}}
          .bar_chart(:class='orientation(question.stats)')
            BarGuts(:stats='question.stats' :total='question.effective_response_count' :account='account')
          .citation Source: Survey of {{question.response_count}} {{account.name}} customers.

      section
        h5 About {{account.name}}
        p(v-html='content_asset.account.introduction')
      .spotlight_fab(v-if='download_url' @click='$modal.show("share_asset_modal", { content_asset: content_asset })')
        DownloadIcon

      section.spotlight_footer
        .ue_logo
          UELogo
        .disclaimer Source: Survey of {{content_asset.respondent_count}} {{account.name}} {{content_asset.filtered_by}} customers. Independent research conducted by UserEvidence. Data verified {{published_at | dayjs('MM/DD/YY')}}.
        //- .disclaimer(v-else) Source: Survey of {{company_qualifier}}. Independent research conducted by <a href='https://www.userevidence.com'>UserEvidence</a>. Data verified {{published_at | dayjs('MMMM DD, YYYY')}}.
        .ueid-container
          .ueid
            | UEID: {{content_asset.identifier}}
            span
          AssetLink(:assetIdentifier='content_asset.identifier' :account='content_asset.account')
</template>
<script lang="ts">
import ReportHeader from './basic_asset_parts/reports/ReportHeader.vue'
import UELogo from './graphics/UELogo.vue'
import AvatarIcon from './graphics/AvatarIcon.vue'
import BackArrow from './graphics/BackArrow.vue'
import Fortune500Icon from './graphics/Fortune500Icon.vue'
import CompanySizeIcon from './graphics/CompanySizeIcon.vue'
import IndustryIcon from './graphics/IndustryIcon.vue'
import LocationIcon from './graphics/LocationIcon.vue'
import DownloadIcon from './graphics/DownloadIcon.vue'
import BarGuts from './BarGuts.vue'
import TestimonialHighlight from './TestimonialHighlight.vue'
import AssetLink from '@components/AssetLink.vue'

export default {
  components: {
    ReportHeader,
    UELogo,
    AvatarIcon,
    BackArrow,
    Fortune500Icon,
    CompanySizeIcon,
    IndustryIcon,
    LocationIcon,
    TestimonialHighlight,
    BarGuts,
    DownloadIcon,
    AssetLink,
  },
  props: ['content_asset', 'horizontal'],
  data() {
    return {
      size_groups: {
        small_business: '1-50',
        medium_enterprise: '50-1000',
        large_enterprise: '> 1000',
        fortune_500: '> 5000',
      },
    }
  },
  computed: {
    primary_color() {
      return this.content_asset?.account?.primary_color || '#850AFF'
    },
    lighter_primary_color() {
      return this.primary_color + '50'
    },
    secondary_color() {
      return this.content_asset?.account?.secondary_color || this.lighter_primary_color
    },
    testimonials() {
      const responses = this.content_asset.responses
      // return this.content_asset.responses
      return responses.sort((a, b) => {
        if (b.text_answer && a.text_answer) return b.text_answer.length - a.text_answer.length
      })
    },
    key_testimonials() {
      const testimonials = this.testimonial_groups
      return testimonials.shift()
    },
    testimonial_groups() {
      var testies = []
      for (var i = 0; i < this.testimonials.length; i++) {
        testies.push(this.testimonials.slice(i * 2, i * 2 + 2))
      }
      return testies.map((g) => g.map((t) => this.testimonial(t)))
    },
    scenario_question() {
      const questions = this.content_asset.questions
      return questions.shift()
    },
    multiple_choice_stat_questions() {
      return this.content_asset.stat_questions.filter((q) => q.type != 'Nps').slice(0, 2)
    },
    nps() {
      return this.content_asset.stat_questions.filter((q) => q.type == 'Nps')[0]
    },
    section_count() {
      return Math.min((this.testimonials.count - 2) / 2, this.content_asset.questions - 1)
    },
    company_article() {
      return this.content_asset.named
        ? ''
        : ['a', 'e', 'i', 'o', 'u'].includes(this.content_asset.company.name[0].toLowerCase())
          ? 'an'
          : 'a'
    },
    company_qualifier() {
      return this.content_asset.company_count > 1 ? 'Companies' : 'Company'
    },
    sector_qualifier() {
      if (this.content_asset.sector_count > 1)
        return `${this.content_asset.sector_count} Industries`
      else {
        if (this.content_asset.filter == 'company') return this.content_asset.sector_name
        else return this.content_asset.filtered_by
      }
    },
    download_url() {
      var v = this.content_asset.variants.find((v) => v.type == 'PdfVariant')
      return v ? v.the_url : null
    },
    published_at() {
      return this.content_asset.verified_at || new Date()
    },
    country_count() {
      var qual = this.content_asset.survey.country_count > 1 ? 'Countries' : 'Country'
      return `${this.content_asset.survey.country_count} ${qual}`
    },
    stats_class() {
      return this.multiple_choice_stat_questions.length == 2 && this.nps ? 'three' : 'two'
    },
    text_color_1() {
      return `color: ${this.primary_color};`
    },
    text_color_2() {
      return `color: ${this.secondary_color};`
    },
    account() {
      return this.content_asset.account
    },
    backUrl() {
      return this.$cookies.get('site-back-url') && this.$cookies.get('site-back-url').length > 5
        ? this.$cookies.get('site-back-url')
        : `/user-research-library/${this.content_asset.account.slug}`
    },
    backText() {
      return this.$cookies.get('site-back-text') && this.$cookies.get('site-back-text').length > 5
        ? this.$cookies.get('site-back-text')
        : `${this.content_asset.account.name} Home`
    },
  },
  methods: {
    orientation(stats) {
      return Math.max(...stats.map((s) => s.the_answer && s.the_answer.length), 0) > 10
        ? 'horizontal'
        : 'vertical'
    },
    testimonial(testimonial) {
      return {
        text_answer: testimonial.text_answer,
        recipient: testimonial.recipient,
        account: this.content_asset.account,
      }
    },
    pluralize(stat) {
      return stat.aggregate_stat_value + '' == '1'
        ? stat.aggregate_qualifier.slice(0, -1)
        : stat.aggregate_qualifier
    },
    formatPrice(amount) {
      return amount.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    },
  },
}
</script>
<style lang="sass" scoped>
header
  background: url('../../images/home/ue-email-signup-background-shape.svg'), var(--header-gradient)
.testimonial
  margin-bottom: 96px
  overflow: visible !important

.bar_guts
  ::v-deep .bar_group
    .channel
      border-radius: 0 8px 8px 0
      background: #F2F6F7 !important
  ::v-deep.legend
    display: none !important
.chart_preview
  .vertical
    height: 387px
  h4
    margin-bottom: 20px
  .citation
    margin-top: 20px
    font-size: 12px
ul
  li
    background: url('graphics/radio_button.svg') no-repeat
    background-position: left center
    padding-left: 20px
    font-weight: 700
    list-style: none
    line-height: 1.5
</style>
