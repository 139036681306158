<template>
  <div class="basic_style_asset stat_asset_basic" :class="{ as_list: renderAsList }">
    <UercRibbon v-if="isUercSurvey" />

    <div class="wrapper">
      <BasicHeader :logoSvg="account.svg_logo_mark" :surveySummary="sourceText" />

      <div class="header d-flex pt-4 align-top">
        <div class="headline mr-2">
          <span v-html="headline" :style="{ color: color }"></span>
        </div>
        <span class="sentence" v-html="sentence"></span>
      </div>
    </div>

    <BasicFooter
      :verifiedAt="contentAsset.verified_at"
      :ueId="contentAsset.identifier"
      :surveySummary="sourceText"
      class="mt-3"
    />
  </div>
</template>
<script lang="ts">
import AssetSourceMixin from '@app/mixins/asset_source'
import { getBasicColor } from '@lib/colors'
import BasicFooter from '@components/basic_asset_parts/BasicFooter.vue'
import BasicHeader from '@components/basic_asset_parts/BasicHeader.vue'
import UercRibbon from '@app/graphics/UercRibbon.vue'

export default {
  name: 'StatBasic',
  components: { BasicHeader, BasicFooter, UercRibbon },
  mixins: [AssetSourceMixin],
  props: {
    contentAsset: { type: Object, required: true },
    account: { type: Object, required: true },

    // This is the color that will be used to fill the Stat number
    statColorOption: { type: String, default: 'primary' },

    // Toggle to check if the Stat should be rendered in a "List" context
    renderAsList: { type: Boolean, required: false, default: false },
  },
  computed: {
    headline() {
      return this.contentAsset.headline || this.contentAsset.rendered_headline
    },
    sentence() {
      return this.contentAsset.sentence || this.contentAsset.rendered_sentence
    },
    color() {
      return this.getBasicColor(this.account, this.statColorOption)
    },
    isUercSurvey() {
      return this.contentAsset?.survey?.type === 'UercSurvey'
    },
  },
  methods: {
    getBasicColor,
  },
}
</script>
<style lang="sass" scoped>
.headline
  font-size: 45px
  font-weight: $font-weight-bold
  line-height: 50px
  word-wrap: break-word

.sentence
  font-size: 16px
  font-weight: 400
  line-height: 25px
  padding-left: 0.5rem

.as_list .header
  .sentence
    padding-left: 0px
</style>
