<template>
  <div class="basic_style_asset bar_chart_basic" :class="{ as_list: renderAsList }">
    <UercRibbon v-if="isUercSurvey" />

    <div class="wrapper">
      <BasicHeader :logoSvg="account.svg_logo_mark" :text="headerText" />

      <BarGutsBasic
        :stats="contentAsset.answer_stats"
        :total="contentAsset.effective_response_count"
        :account="account"
        :chartColor="color"
      />
    </div>

    <BasicFooter
      :verifiedAt="contentAsset.verified_at"
      :ueId="contentAsset.identifier"
      :surveySummary="sourceText"
      class="mt-3"
    />
  </div>
</template>
<script lang="ts">
import AssetSourceMixin from '@app/mixins/asset_source'
import HeaderTextMixin from '@app/mixins/header_text'
import { getBasicColor } from '@lib/colors'
import BarGutsBasic from './basic_asset_parts/bar_chart/BarGutsBasic.vue'
import BasicFooter from '@components/basic_asset_parts/BasicFooter.vue'
import BasicHeader from '@components/basic_asset_parts/BasicHeader.vue'
import UercRibbon from '@app/graphics/UercRibbon.vue'

export default {
  components: { BarGutsBasic, BasicFooter, BasicHeader, UercRibbon },
  mixins: [AssetSourceMixin, HeaderTextMixin],
  props: {
    contentAsset: { type: Object, required: true },
    account: { type: Object, required: true },

    // This is the color that will be used to fill the chart bar
    chartColorOption: { type: String, default: 'primary' },

    renderAsList: { type: Boolean, required: false, default: false },
  },
  computed: {
    color() {
      return this.getBasicColor(this.account, this.chartColorOption)
    },
    isUercSurvey() {
      return this.contentAsset?.survey?.type === 'UercSurvey'
    },
  },
  methods: {
    getBasicColor,
  },
}
</script>
<style lang="sass" scoped></style>
