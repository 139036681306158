import axiosBase from 'axios'
import { getCsrfToken } from '@lib/csrf'

const axiosConfigured = axiosBase.create()

const csrfToken = getCsrfToken()
if (csrfToken) {
  const csrfHeaderName = 'X-CSRF-Token'
  axiosConfigured.defaults.headers.post[csrfHeaderName] = csrfToken
  axiosConfigured.defaults.headers.put[csrfHeaderName] = csrfToken
  axiosConfigured.defaults.headers.patch[csrfHeaderName] = csrfToken
  axiosConfigured.defaults.headers.delete[csrfHeaderName] = csrfToken
}

/*
 * An axios instance that is configured to pass the csrf token for
 * non-GET requests. This client should be suitable for use as the standard
 * axios client throughout our frontend code base.
 */
export default axiosConfigured
