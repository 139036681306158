<template>
  <div
    class="branded-footer stat-branded-footer d-flex justify-content-between"
    :style="{ color: color }"
  >
    <div><span class="logo mr-1" v-html="svgUeLogo"></span> UE verified</div>

    <a :style="{ color: color }" class="ueid-link" :href="shareLink">uevi.co/{{ ueId }}</a>
  </div>
</template>
<script lang="ts">
import ueLogoSvg from '@images/ue_logo_mark.svg?raw'
import { generateShareLink } from '@lib/share_link'

export default {
  props: {
    ueId: { type: String, required: true },
    color: { type: String },
  },
  components: {},
  computed: {
    shareLink() {
      return generateShareLink(this.ueId)
    },
    svgUeLogo() {
      return ueLogoSvg
    },
  },
}
</script>
<style lang="sass" scoped></style>
