<template>
  <svg viewBox="0 0 160 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g class="ue-logo-symbol" fill="#850AFF">
      <path d="M19 16L26 0H21L15.8667 11.7333L14 8H9L13 16H19Z" />
      <path
        d="M16 27C22.0751 27 27 22.0751 27 16H32C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16H5C5 22.0751 9.92487 27 16 27Z"
      />
    </g>
    <g class="ue-logo-wordmark" :fill="wordmark_fill">
      <path
        d="M52.8313 9H50.1619V17.6996C50.1619 19.8313 49.2785 20.8299 47.4156 20.8299C45.5528 20.8299 44.6502 19.8313 44.6502 17.6996V9H42V17.8148C42 21.2716 43.882 23 47.4156 23C50.9492 23 52.8313 21.2716 52.8313 17.8148V9Z"
      />
      <path
        d="M58.7891 23C61.6506 23 63.1677 21.7517 63.1677 20.0233C63.1677 18.4294 62.1307 17.5844 59.3269 17.0274C57.7137 16.701 57.2336 16.3745 57.2336 15.856C57.2336 15.3567 57.8865 14.9342 58.962 14.9342C60.2487 14.9342 61.0168 15.4335 61.5738 16.4321L63.2061 15.0494C62.3227 13.6667 61.1321 13.0137 58.9236 13.0137C56.0813 13.0137 54.6026 14.5501 54.6026 16.1056C54.6026 17.6996 55.6012 18.6214 58.5971 19.2167C59.999 19.5048 60.5175 19.7737 60.5175 20.2154C60.5175 20.7339 59.8838 21.0796 58.7507 21.0796C57.4448 21.0796 56.4078 20.7339 55.8701 19.5816L54.3337 21.1564C55.2171 22.4623 56.8111 23 58.7891 23Z"
      />
      <path
        d="M73.7214 17.9685C73.7214 14.8189 72.0891 13.0137 69.0932 13.0137C66.0013 13.0137 64.2537 14.8573 64.2537 18.0069C64.2537 21.1564 66.0589 23 69.2468 23C71.4169 23 72.8957 22.1742 73.5102 20.6379L71.3593 19.8505C71.0904 20.6187 70.3607 21.0412 69.2468 21.0412C67.9601 21.0412 67.1151 20.3882 66.8463 19.0055H73.6446C73.683 18.7558 73.7214 18.2373 73.7214 17.9685ZM69.074 14.9534C70.2839 14.9534 71.0904 15.7215 71.3017 17.1619H66.8271C67.0383 15.7023 67.8065 14.9534 69.074 14.9534Z"
      />
      <path
        d="M79.7234 13.0137C78.2255 13.0137 78.0238 13.7627 77.5245 15.0878L77.3517 13.2058H75.0663V23H77.6973V17.834C77.6973 16.0864 78.8496 15.2414 80.0019 15.2414C80.5012 15.2414 80.8889 15.2222 80.8889 15.2222V13.225C80.8889 13.0905 80.1459 13.0137 79.7234 13.0137Z"
      />
      <path
        d="M84.7778 20.6667V16.7778H90.2222V14.4444H84.7778V11.3333H91.7778V9H82.4444V23H92.1667V20.6667H84.7778Z"
      />
      <path
        d="M100.182 12.8889L97.762 20.9643L95.3999 12.8889H92.5768L96.2257 23H99.26L102.909 12.8889H100.182Z"
      />
      <path
        d="M105.583 11.7222C106.75 11.7222 107.139 11.1999 107.139 10.5556C107.139 9.77778 106.75 9 105.583 9C104.417 9 103.833 9.77778 103.833 10.5556C103.833 11.3149 104.417 11.7222 105.583 11.7222ZM106.944 12.8889H104.222V23H106.944V12.8889Z"
      />
      <path
        d="M115.5 9V14.7613C114.943 13.7051 113.906 13.0137 112.504 13.0137C109.969 13.0137 108.394 14.8765 108.394 18.0261C108.394 21.1564 109.95 23 112.504 23C113.983 23 115.116 22.2318 115.673 21.0796L115.788 23H118.131V9H115.5ZM113.291 20.9835C111.889 20.9835 111.044 19.9657 111.044 18.0069C111.044 16.048 111.889 15.0302 113.291 15.0302C114.559 15.0302 115.365 15.9136 115.5 17.4499V18.5638C115.365 20.1385 114.539 20.9835 113.291 20.9835Z"
      />
      <path
        d="M128.768 17.9685C128.768 14.8189 127.135 13.0137 124.14 13.0137C121.048 13.0137 119.3 14.8573 119.3 18.0069C119.3 21.1564 121.105 23 124.293 23C126.463 23 127.942 22.1742 128.557 20.6379L126.406 19.8505C126.137 20.6187 125.407 21.0412 124.293 21.0412C123.006 21.0412 122.161 20.3882 121.893 19.0055H128.691C128.729 18.7558 128.768 18.2373 128.768 17.9685ZM124.12 14.9534C125.33 14.9534 126.137 15.7215 126.348 17.1619H121.873C122.085 15.7023 122.853 14.9534 124.12 14.9534Z"
      />
      <path
        d="M135.836 13.0137C134.395 13.0137 133.205 13.6283 132.571 14.915L132.475 13.2058H130.113V23H132.744V17.738C132.744 15.8176 133.838 15.0878 134.952 15.0878C135.951 15.0878 136.661 15.6255 136.661 17.2771V23H139.292V16.5089C139.292 14.1852 137.833 13.0137 135.836 13.0137Z"
      />
      <path
        d="M145.286 13.0137C142.214 13.0137 140.389 14.8573 140.389 18.0069C140.389 21.1564 142.195 23 145.383 23C147.821 23 149.319 21.8093 149.723 20.1001L147.341 19.332C147.149 20.4266 146.496 21.0412 145.344 21.0412C143.923 21.0412 143.02 20.0809 143.02 18.0261C143.02 16.0096 143.865 14.9726 145.363 14.9726C146.381 14.9726 146.996 15.5679 147.226 16.7202L149.684 15.8368C149.223 14.262 147.994 13.0137 145.286 13.0137Z"
      />
      <path
        d="M159.971 17.9685C159.971 14.8189 158.339 13.0137 155.343 13.0137C152.251 13.0137 150.504 14.8574 150.504 18.0069C150.504 21.1564 152.309 23 155.497 23C157.667 23 159.146 22.1742 159.76 20.6379L157.609 19.8505C157.34 20.6187 156.611 21.0412 155.497 21.0412C154.21 21.0412 153.365 20.3882 153.096 19.0055H159.895C159.933 18.7558 159.971 18.2373 159.971 17.9685ZM155.324 14.9534C156.534 14.9534 157.34 15.7216 157.552 17.1619H153.077C153.288 15.7023 154.057 14.9534 155.324 14.9534Z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
export default {
  props: ['fill'],
  computed: {
    wordmark_fill() {
      return this.fill ? this.fill : '#1B2022'
    },
  },
  mounted() {
    // this.fill = this.fill ? this.fill : 'white'
  },
}
</script>
