<template>
  <div class="uerc-ribbon d-flex align-items-center">
    <div class="ue-badge">
      <svg
        width="35"
        height="36"
        viewBox="0 0 46 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4506 4.61037C21.6238 2.71873 24.3762 2.71873 25.5494 4.61037L26.5682 6.25298C27.3396 7.49662 28.8957 8.00224 30.2508 7.44951L32.0405 6.71945C34.1016 5.87872 36.3283 7.49648 36.1656 9.71649L36.0243 11.6442C35.9174 13.1038 36.8791 14.4275 38.3002 14.7768L40.1773 15.2381C42.3389 15.7694 43.1894 18.387 41.7529 20.0874L40.5055 21.564C39.5611 22.6819 39.5611 24.3181 40.5055 25.436L41.7529 26.9126C43.1894 28.613 42.3389 31.2306 40.1773 31.7619L38.3002 32.2232C36.8791 32.5725 35.9174 33.8962 36.0243 35.3558L36.1656 37.2835C36.3283 39.5035 34.1016 41.1213 32.0405 40.2805L30.2508 39.5505C28.8957 38.9978 27.3396 39.5034 26.5682 40.747L25.5494 42.3896C24.3762 44.2813 21.6238 44.2813 20.4506 42.3896L19.4318 40.747C18.6604 39.5034 17.1043 38.9978 15.7492 39.5505L13.9595 40.2805C11.8984 41.1213 9.67174 39.5035 9.83442 37.2835L9.97568 35.3558C10.0826 33.8962 9.1209 32.5725 7.69976 32.2232L5.82271 31.7619C3.66109 31.2306 2.81058 28.613 4.24708 26.9126L5.49446 25.436C6.43887 24.3181 6.43887 22.6819 5.49446 21.564L4.24708 20.0874C2.81058 18.387 3.66109 15.7694 5.82271 15.2381L7.69976 14.7768C9.1209 14.4275 10.0826 13.1038 9.97568 11.6442L9.83442 9.71649C9.67174 7.49648 11.8984 5.87871 13.9595 6.71945L15.7492 7.44951C17.1043 8.00224 18.6604 7.49663 19.4318 6.25298L20.4506 4.61037Z"
          fill="white"
        />
        <path
          d="M22.9998 29.4178C26.5905 29.4178 29.5014 26.507 29.5014 22.9163H32.4567C32.4567 28.1392 28.2227 32.3731 22.9998 32.3731C17.7769 32.3731 13.543 28.1392 13.543 22.9163H16.4982C16.4982 26.507 19.4091 29.4178 22.9998 29.4178Z"
          fill="#850AFF"
        />
        <path d="M28.9093 13.4595L24.7719 22.9163H21.8167L25.954 13.4595H28.9093Z" fill="#850AFF" />
        <path d="M18.8638 18.1882L21.228 22.9167H24.1833L21.819 18.1882H18.8638Z" fill="#850AFF" />
      </svg>
    </div>
    <div class="title">Research Content</div>
  </div>
</template>
<script lang="ts">
export default {
  name: 'UercRibbon',
}
</script>
<style lang="sass" scoped>
.uerc-ribbon
  padding: 10px 14px
  background: linear-gradient(108.88deg, #782BF7 46.82%, #A92BF7 84.71%)
  color: white
  .ue-badge
    padding-right: 13px
    margin-right: 16px
    border-right: 2px solid white
  .title
    font-size: 18px
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
</style>
