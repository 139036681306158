<template>
  <div class="basic-footer d-flex justify-content-between">
    <div class="verified-at">
      <div>
        <span class="logo mr-1" v-html="svgUeLogo"></span> UE verified: {{ formatedVerifiedAt }}
      </div>
      <div v-if="surveySummary" class="survey-summary">
        {{ surveySummary }}
      </div>
    </div>
    <a class="ueid-link" :href="shareLink">uevi.co/{{ ueId }}</a>
  </div>
</template>
<script lang="ts">
import { formatDate } from '@lib/formatters'
import ueLogoSvg from '@images/ue_logo_mark.svg?raw'
import { generateShareLink } from '@lib/share_link'

export default {
  props: {
    verifiedAt: { type: String, required: false },
    ueId: { type: String, required: false },
    surveySummary: { type: String, required: false }, // testimonials do not have a survey summary
  },
  components: {},
  computed: {
    formatedVerifiedAt() {
      // Use current date/time if we're dealing with an asset that has not been
      // finalized yet
      const effectiveVerifiedAt = this.verifiedAt || new Date()
      return formatDate(effectiveVerifiedAt, 'MM/D/YYYY')
    },
    svgUeLogo() {
      return ueLogoSvg
    },
    shareLink() {
      return generateShareLink(this.ueId)
    },
  },
}
</script>
<style lang="sass" scoped>
.basic-footer
  font-size: 14px
  padding: 16px
  margin: 0 8px
  font-weight: 600
  border-top: 1px solid $border-color

.verified-at
  color: $ueBlack

.logo::v-deep svg
  height: 1em
  width: 1em
  path
    fill: currentColor

.ueid-link
  text-decoration: none
  color: $basic-style-footer-link-color
.survey-summary
  font-size: 0.6666rem
  font-weight: 400
</style>
