<template>
  <div
    class="segment"
    :style="{ flexBasis: `${segment.percentage}%`, backgroundColor: fillColor }"
    ref="container"
    :class="{ 'segment--dark-bg': dark_bg }"
  >
    <div class="segment__label" ref="child" v-if="show_label">{{ segment.percentage }}%</div>
  </div>
</template>
<script lang="ts">
import Color from 'color'
export default {
  components: {},
  props: {
    segment: {
      type: Object,
      required: true,
    },
    fillColor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show_label: true, // this has to start true for the mounted() method to compute its size
      dark_bg: false,
    }
  },
  mounted() {
    // hide the label if the segment is too small to fit it
    const parent = this.$refs.container
    const parent_width = parent?.getBoundingClientRect().width
    const child = this.$refs.child
    const child_width = child?.getBoundingClientRect().width
    this.show_label = parent_width > child_width

    // decide if the text should be white or black for best contrast
    const style_color = window.getComputedStyle(parent).backgroundColor
    const bg_color = Color(style_color)
    this.dark_bg = bg_color.isDark()
  },
}
</script>
<style lang="sass" scoped>
.segment--dark-bg
  .segment__label
    color: #fff
</style>
